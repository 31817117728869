import { Fragment, useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { GROUP_STATUSES } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { Icon, ChooseStatusChipOption } from 'shared/components/ui';
import { SpreadsheetStats } from 'shared/components/ui/SpreadsheetStats';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { useSpreadsheetSearch } from 'shared/features/search';
import { Spreadsheet, SpreadsheetCellActions } from 'shared/features/spreadsheet';
import { useSpreadsheetContext } from 'shared/features/spreadsheet/providers';
import {
  Group,
  GroupMembersStatsQuery,
  GroupMembersStatsQueryVariables,
  GroupApproveMutation,
  GroupApproveMutationVariables,
  GroupRejectMutation,
  GroupRejectMutationVariables,
  GroupsListQuery,
  GroupsListQueryVariables,
  GroupFilter,
  GroupsListItemFragment,
} from 'shared/graphql';
import { useInboxContext } from 'providers/InboxProvider';
import { useCrudPermissions, useDownloadLazyQueryCSV } from 'shared/hooks';
import { formatTableDate } from 'shared/utils/date';
import { formatUserName } from 'shared/utils/user';
import { getFileNameWithTimestamp } from 'shared/utils/file';
import { GROUPS_LIST_QUERY } from 'features/groups/queries';

import {
  canActivateGroup,
  canDeactivateGroup,
  GroupSpreadsheetFilters,
  GroupSpreadSheetHeaders,
  GroupsSpreadsheetHeader,
} from '../constants';
import {
  GROUP_APPROVE_MUTATION,
  GROUP_MEMBERS_STATS_QUERY,
  GROUP_REJECT_MUTATION,
} from '../queries';
import { ChooseGroupStatusChip } from './ChooseGroupStatusChip';

interface Props {
  onDetailsModalOpen: (id: string) => void;
  onAdminsModalOpen: (id: string) => void;
}

export function GroupsSpreadSheet({ onDetailsModalOpen, onAdminsModalOpen }: Props) {
  const { onOpenInboxModal } = useInboxContext();
  const { groupsPermissions, loading: loadingAddons } = useCrudPermissions();
  const { queryParams, currentRowId, selected } = useSpreadsheetContext();

  const { data: tableStats } = useQuery<GroupMembersStatsQuery, GroupMembersStatsQueryVariables>(
    GROUP_MEMBERS_STATS_QUERY,
    {
      variables: {
        pendingFilter: { status: { equals: 'pending' } },
      },
    },
  );

  const { tableData, tableLoading, queryVariables } = useSpreadsheetSearch<GroupsListQuery>({
    query: GROUPS_LIST_QUERY,
    searchingFields: ['title', 'createdBy.fullName', 'createdBy.firstName', 'createdBy.lastName'],
    queryVariables: queryParams,
  });

  const downloadFilter = useMemo(() => {
    const filter = createFilterBuilder<GroupFilter>(queryVariables.filter);

    if (selected.length > 0) {
      // Exclude the others by filter with selected IDs.
      return { id: { in: selected } };
    }

    return filter.build();
  }, [queryVariables, selected]);

  const [downloadHandler] = useDownloadLazyQueryCSV<GroupsListQuery, GroupsListQueryVariables>(
    GROUPS_LIST_QUERY,
    {
      filename: getFileNameWithTimestamp('Groups.csv'),

      variables: {
        filter: downloadFilter,
      },

      transform(response) {
        const groups = response.groupsList.items ?? [];
        return groups.map(transformExport);
      },
    },
  );

  const [groupRejectRequest] = useMutation<GroupRejectMutation, GroupRejectMutationVariables>(
    GROUP_REJECT_MUTATION,
    {
      refetchQueries: ['GroupsList', 'GroupMembersStats'],
      awaitRefetchQueries: true,
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: 'Group is now inactive.',
        [SNACKBAR_ERROR_MESSAGE]: `Error! Your request was not sent`,
      },
    },
  );

  const [groupApproveRequest] = useMutation<GroupApproveMutation, GroupApproveMutationVariables>(
    GROUP_APPROVE_MUTATION,
    {
      refetchQueries: ['GroupsList', 'GroupMembersStats'],
      awaitRefetchQueries: true,
      context: {
        [SNACKBAR_SUCCESS_MESSAGE]: 'Group is now active.',
        [SNACKBAR_ERROR_MESSAGE]: `Error! Your request was not sent`,
      },
    },
  );

  const getAvailableActions = useCallback(
    (status: string) => {
      return {
        withApproveAction: canActivateGroup(status) && groupsPermissions.edit,
        withRejectAction: canDeactivateGroup(status) && groupsPermissions.delete,
      };
    },
    [groupsPermissions],
  );

  const spreadsheetActions = useMemo((): SpreadsheetCellActions => {
    const currentRow = tableData?.groupsList?.items.find(group => {
      return group.id === currentRowId;
    });

    const resultActions = [
      {
        id: 'View Details',
        title: 'View Details',
        onClickAction: (id: string) => onDetailsModalOpen(id),
        icon: <Icon name="Edit" />,
      },
      {
        id: 'Message',
        title: 'Message',
        onClickAction: () => {
          onOpenInboxModal?.({
            isOpen: true,
            options: {
              members: [currentRow as Group],
              messageType: 'group',
            },
          });
        },
        icon: <Icon name="ForumTwoTone" />,
      },
      {
        id: 'View Admins',
        title: 'View Admins',
        onClickAction: (id: string) => onAdminsModalOpen(id),
        icon: <Icon name="Star" />,
      },
    ];

    const { withApproveAction, withRejectAction } = getAvailableActions(
      currentRow?.status as string,
    );

    if (withApproveAction) {
      resultActions.unshift({
        id: 'Approve Request',
        title: 'Approve Request',
        onClickAction: (id: string) => groupApproveRequest({ variables: { groupId: id } }),
        icon: <Icon name="CheckCircle" />,
      });
    }

    if (withRejectAction) {
      resultActions.push({
        id: 'Reject',
        title: 'Reject',
        onClickAction: (id: string) => groupRejectRequest({ variables: { groupId: id } }),
        icon: <Icon name="DeleteForeverSharp" />,
      });
    }

    return resultActions;
  }, [
    getAvailableActions,
    tableData,
    currentRowId,
    onDetailsModalOpen,
    onOpenInboxModal,
    onAdminsModalOpen,
    groupRejectRequest,
    groupApproveRequest,
  ]);

  const getGroupStatusComponent = useCallback(
    group => {
      const options: ChooseStatusChipOption[] = [];

      const { withApproveAction, withRejectAction } = getAvailableActions(group.status);

      if (withApproveAction) {
        options.push({
          label: 'Active',
          value: GROUP_STATUSES.active,
          onClick: () => groupApproveRequest({ variables: { groupId: group.id } }),
        });
      }

      if (withRejectAction) {
        options.push({
          label: 'Inactive',
          value: GROUP_STATUSES.inactive,
          onClick: () => groupRejectRequest({ variables: { groupId: group.id } }),
        });
      }

      return <ChooseGroupStatusChip status={group.status} options={options} />;
    },
    [getAvailableActions, groupApproveRequest, groupRejectRequest],
  );

  const newData = useMemo(() => {
    const groups = tableData?.groupsList?.items ?? [];

    return groups
      .map(normalizeGroup)
      .map(group => ({ ...group, status: getGroupStatusComponent(group) }));
  }, [getGroupStatusComponent, tableData]);

  const mainToolbarAction = useMemo(
    () => ({
      icon: <Icon name="Forum" color="secondary" />,
      label: 'Message',
      onClick: (ids: string[]) => {
        const totalGroups: any = ids.map(id =>
          tableData?.groupsList?.items.find(group => group.id === id),
        );

        if (onOpenInboxModal) {
          onOpenInboxModal({
            isOpen: true,
            options: {
              members: totalGroups,
              messageType: 'group',
            },
          });
        }
      },
    }),
    [onOpenInboxModal, tableData],
  );

  const { totalGroups, totalGroupsPending, totalMembers } = useMemo(() => {
    const groupsData = tableStats?.groupsCount;
    const pendingCount = +(tableStats?.groupsPendingCount?.count || 0);

    return {
      totalGroups: groupsData?.count || 0,
      totalGroupsPending: pendingCount || 0,
      totalMembers: (groupsData?.items || []).reduce(
        (acc, group) => acc + (group?.members?.count ?? 0),
        0,
      ),
    };
  }, [tableStats]);

  const Stats = useCallback(() => {
    return (
      <SpreadsheetStats
        data={[
          { value: totalGroups, label: 'Total groups' },
          { value: totalMembers, label: 'Total Members' },
          { value: totalGroupsPending, label: 'Pending approval', color: 'secondary' },
        ]}
      />
    );
  }, [totalGroups, totalGroupsPending, totalMembers]);

  return (
    <Fragment>
      <Stats />

      <Spreadsheet
        data={newData}
        headlines={GroupsSpreadsheetHeader}
        toolbarOptions={{
          filters: GroupSpreadsheetFilters,
          mainToolbarAction,
          withPerPage: true,
          withDownload: true,
          downloadHandler,
          rawData: tableData?.groupsList?.items ?? [],
        }}
        cellActions={spreadsheetActions}
        itemsCount={tableData?.groupsList.count || 0}
        loading={tableLoading || loadingAddons}
      />
    </Fragment>
  );
}

function normalizeGroup(group: GroupsListItemFragment) {
  return {
    id: group.id as string,
    title: group.title ?? '(Unknown)',
    createdBy: formatUserName(group.creator),
    createdAt: formatTableDate(group.createdAt),
    posts: group.posts?.count ?? 0,
    members: group.membersCount ?? 0,
    status: group.status,
  };
}

function transformExport(group: GroupsListItemFragment) {
  return {
    [GroupSpreadSheetHeaders.title]: group.title ?? '(Unknown)',
    [GroupSpreadSheetHeaders.createdBy]: formatUserName(group.creator),
    [GroupSpreadSheetHeaders.createdOn]: formatTableDate(group.createdAt),
    [GroupSpreadSheetHeaders.post]: group.posts?.count ?? 0,
    [GroupSpreadSheetHeaders.member]: group.membersCount ?? 0,
    [GroupSpreadSheetHeaders.status]: group.status,
  };
}

import { ChangeEvent, useCallback, useRef } from 'react';
import { css } from '@emotion/react';
import { Box, Checkbox, IconButton, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { Print, CloudDownload, CloudUpload, Search } from '@material-ui/icons';

import { Select, Button, Popover } from 'shared/components/ui';
import { SearchControl } from 'shared/features/search';
import {
  SpreadsheetBulkAction,
  SpreadsheetFilterPopover,
  SpreadsheetFiltersType,
  SpreadsheetToolbarOptions,
  TABLE_PAGE_SIZE_VARIANTS,
  useSpreadsheetContext,
} from 'shared/features/spreadsheet';
import { useResponsive } from 'shared/hooks';

interface Props {
  filters?: SpreadsheetFiltersType;
  onSelectAll: () => void;
  isRowsSelected: boolean;
  toolbarOptions?: SpreadsheetToolbarOptions;
  onClickMainAction: () => void;
  toolbarHeader?: JSX.Element;
  textToolbarHeader?: string;
}

const FILE_TYPE = '.csv';

export function SpreadsheetToolbar({
  onSelectAll,
  isRowsSelected,
  onClickMainAction,
  toolbarOptions,
  toolbarHeader,
  textToolbarHeader,
}: Props) {
  const { selected, pageSize, setPageSize, setSelected } = useSpreadsheetContext();
  const { isDesktop } = useResponsive();

  const onChangePageSize = useCallback(
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize],
  );

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onUploadFile = () => {
    hiddenFileInput?.current?.click();
  };

  const wrapBulkAction = useCallback(
    (action: SpreadsheetBulkAction['onClick']) => () => {
      action(selected);
    },
    [selected],
  );

  const isMainAction = !!toolbarOptions?.mainToolbarAction;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="1rem"
      gridGap="1rem"
    >
      <Box display="flex" alignItems="center" gridGap="0.5rem" flex={1} flexWrap="wrap">
        {toolbarHeader && <Box flex={1}>{toolbarHeader}</Box>}

        <Checkbox color="secondary" checked={isRowsSelected} onChange={onSelectAll} />

        {isMainAction && (
          <Box display="flex" alignItems="center">
            {toolbarOptions?.mainToolbarAction?.icon && (
              <Button
                variant="text"
                startIcon={toolbarOptions?.mainToolbarAction?.icon}
                disabled={!!toolbarOptions.mainToolbarAction.disabled}
                onClick={onClickMainAction}
              >
                {toolbarOptions?.mainToolbarAction?.label}
              </Button>
            )}
          </Box>
        )}

        {toolbarOptions?.bulkActions?.map(action => (
          <Tooltip title={action.description ?? ''}>
            <Button
              size="small"
              variant="text"
              startIcon={action.icon}
              disabled={action.disabled}
              onClick={wrapBulkAction(action.onClick)}
              disableElevation
            >
              {action.label}
            </Button>
          </Tooltip>
        ))}
      </Box>

      <Box display="flex" justifyContent={isMainAction ? 'space-between' : 'flex-end'}>
        <Box display="flex" alignItems="center" gridGap="0.5rem">
          {toolbarOptions?.withPerPage && (
            <Box display="flex" alignItems="center">
              {isDesktop && (
                <Typography
                  style={{
                    marginRight: 10,
                    fontFamily: 'Barlow',
                    letterSpacing: '0.7px',
                    color: '#9A9A9A',
                    fontSize: '11px',
                  }}
                >
                  RESULTS PER PAGE
                </Typography>
              )}

              <Select
                size="small"
                variant="outlined"
                value={pageSize}
                onChange={onChangePageSize}
                style={{ minWidth: 'auto' }}
              >
                {TABLE_PAGE_SIZE_VARIANTS.map(el => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}

          <Box display="flex" gridGap="0.5rem">
            {toolbarOptions?.withSearch !== false && (
              <Popover
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                target={
                  <Tooltip title="Search">
                    <IconButton>
                      <Search />
                    </IconButton>
                  </Tooltip>
                }
              >
                <SearchControl textFieldProps={{ trackSearches: false }} withTextField />
              </Popover>
            )}

            {toolbarOptions?.withPDFDownload && (
              <Tooltip title="Print as PDF">
                <IconButton
                  onClick={() =>
                    toolbarOptions?.downloadPDFHandler?.(toolbarOptions?.rawData, textToolbarHeader)
                  }
                >
                  <Print />
                </IconButton>
              </Tooltip>
            )}

            {!!toolbarOptions?.filters && (
              <SpreadsheetFilterPopover filters={toolbarOptions.filters} />
            )}

            {toolbarOptions?.withUpload && (
              <Tooltip title="Upload from CSV">
                <IconButton onClick={onUploadFile}>
                  <CloudUpload />

                  <input
                    type="file"
                    accept={FILE_TYPE}
                    ref={hiddenFileInput}
                    style={{ display: 'none' }}
                    onChange={toolbarOptions.onUpload}
                  />
                </IconButton>
              </Tooltip>
            )}

            {toolbarOptions?.withDownload && (
              <Tooltip title="Select data to download only specific records">
                <IconButton
                  onClick={() => toolbarOptions?.downloadHandler?.(toolbarOptions?.rawData)}
                >
                  <CloudDownload />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const tableCellCheckboxCss = css`
  border: none;
`;
